import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hello from '../images/hello.jpg';

const ContactPage = () => (
  <Layout>
    <SEO title="Kontakt" />

    <div className="site-blocks-cover inner-page overlay" style={{ backgroundImage: `url(${Hello})` }}>
      <div className="row align-items-center justify-content-center mx-2">
        <div className="col-12 text-center">
          <h1 className="text-uppercase text-center"><span>Fragen<ins className="text-decoration-none d-none d-sm-inline"> zu Helmart's Foodtruck</ins>?</span></h1>
          <span className="caption bg-primary p-2 text-white text-center">Gerne beantworten wir Ihre Fragen auch auf Facebook, Instagram und Whatsapp</span>
          <div className="social-links pt-5">
            <a href="https://www.facebook.com/Helmarts-107427394136963/" rel="noopener noreferrer nofollow" target="_blank" className="contact-facebook"><i className="fa fa-facebook"></i> Facebook</a>
            <a href="https://www.instagram.com/helmarts_food_truck/" rel="noopener noreferrer nofollow" target="_blank" className="contact-instagram"><i className="fa fa-instagram"></i> Instagram</a>
            <a href="https://wa.me/436606933305" rel="noopener noreferrer nofollow" target="_blank" className="contact-whatsapp"><i className="fa fa-instagram"></i> Whatsapp</a>
          </div>
        </div>
      </div>
    </div>

    <div className="yellow-lines"></div>

    <div className="site-section first-section py-5">
      <div className="container">
        <div className="row">
       
          <div className="col-md-12 col-lg-8 mb-5">
            <form className="p-5 bg-white" name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
            <input type="hidden" name="form-name" value="contact" />
              <div className="row form-group">
                <div className="col-md-12 mb-3 mb-md-0">
                  <label className="font-weight-bold w-100" for="fullname-field">Name
                    <input type="text" id="fullname-field" name="fullname" className="form-control" placeholder="Name" />
                  </label>
                </div>
              </div>
              <div className="row form-group">
                <div className="col-md-12">
                  <label className="font-weight-bold w-100" for="email-field">E-Mail
                    <input type="email" id="email-field" name="email" className="form-control" placeholder="E-Mail Adresse" />
                  </label>
                </div>
              </div>


              <div className="row form-group">
                <div className="col-md-12 mb-3 mb-md-0">
                  <label className="font-weight-bold w-100" for="phone">Telefon
                    <input type="text" id="phone" className="form-control" placeholder="Telefonnummer" />
                  </label>
                </div>
              </div>

              <div className="row form-group">
                <div className="col-md-12 mb-3 mb-md-0">
                  <div className="font-weight-bold form-label">Betreff</div>
                  <div className="custom-controls-stacked">
                    <label className="custom-control custom-radio">
                      <input type="radio" className="custom-control-input" name="subject" id="subject-field1" value="misc" />
                      <div className="custom-control-label">Allgemeine Frage</div>
                    </label>
                    <label className="custom-control custom-radio d-none">
                      <input type="radio" className="custom-control-input" name="subject" id="subject-field2" value="catering" />
                      <div className="custom-control-label">Catering / Events</div>
                    </label>
                    <label className="custom-control custom-radio">
                      <input type="radio" className="custom-control-input" name="subject" id="subject-field3" value="feedback" />
                      <div className="custom-control-label">Feedback</div>
                    </label>
                  </div>
                </div>
              </div>

              <div className="row form-group">
                <div className="col-md-12">
                  <label className="font-weight-bold w-100" for="message">Ihre Fragen
                    <textarea name="message" id="message" cols="30" rows="5" className="form-control" placeholder=""></textarea>
                  </label> 
                </div>
              </div>

              <div className="row form-group">
                <div className="col-md-12">
                  <input type="submit" value="Nachricht senden" className="btn btn-primary text-white px-4 py-2" />
                </div>
              </div>

  
            </form>
          </div>

          <div className="col-lg-4">
            <div className="p-4 mb-3 bg-white">
              <h3 className="h5 text-black mb-3">Kontaktinformationen:</h3>
              <p className="mb-0 font-weight-bold">Adresse</p>
              <p className="mb-4">Patrick Graf<br/>Thürnlhofstraße 5/6/606<br/>1110 Wien</p>

              <p className="mb-0 font-weight-bold">Telefon</p>
              <p className="mb-4"><a href="tel:+436606933305">+43 660 69 333 05</a></p>

              <p className="mb-0 font-weight-bold">E-Mail Adresse</p>
              <p className="mb-0"><a href="mailto:office@helmarts.at">office@helmarts.at</a></p>

            </div>
            
            
          </div>
        </div>
      </div>
    </div>


  </Layout>
)

export default ContactPage
